import React from "react";
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../../../components/layout";
import SEO from "../../../components/seo";
import * as CONST from "../../../components/const";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// import bg from "../../../images/employment/requirements/bg.png";
const kv = "../../../images/employment/requirements/kv_rc.jpg";
const img1 = "../../../images/employment/requirements/img1_rc.jpg";
const bg = "../../../images/employment/requirements/Requirements_org.png";
const bg_b = "../../../images/employment/requirements/Requirements_b.png";


import NEED from "../../../components/need";
import QA from "../../../components/qa";

function IndexPage() {
	const STATIC_IMAGE_PLACEHOLDER = "blurred"; // blurred tracedSVG none
	const minText = { fontSize: '0.8rem' };
	const flowCommonClass = 'relative p-3 2xl:p-5 pb-10 flex flex-row flex-wrap items-center justify-center z-10';
	const flowBlockClass = flowCommonClass + ' bg-black text-white ';
	const flowWhiteClass = flowCommonClass + ' bg-white text-black ';
	const flowDiscriptionClass = ' text-base my-3 w-full '

	return (
		<Layout addClassName="bg-gray-light">
			<SEO
				description="ALPHA WAVEの募集要項です。当社が求める資質、選考プロセスなどをご覧ください。"
				title="Requirements"
			/>

			<section className={CONST.SECTION_AREA_CLASS}>
				<div className={CONST.KEY_VISUAL_TITLE_MESSAGE_AREA_CLASS}>
					{/* <div className={CONST.KEY_VISUAL_TITLE_AREA_CLASS}> */}
					<div className={CONST.PC_SHOW_CLASS + " py-8"}>
						{/* <p className={CONST.KEY_VISUAL_TITLE_EN_CLASS}>Employment information <span className={CONST.KEY_VISUAL_TITLE_JP_CLASS}>採用情報</span></p> */}
					</div>
					<StaticImage quality="100" layout="fullWidth" alt="" placeholder={STATIC_IMAGE_PLACEHOLDER} className={CONST.PC_HIDDEN_CLASS + " w-full"} src={kv} />
					<div className={CONST.KEY_VISUAL_MESSAGE_AREA_CLASS}>
						<div>
							<h1 className={CONST.H1_CLASS}>募集要項</h1>
							<p>当社は学校でプログラムを学んだ方は勿論、全くの未経験の方の入社も心から歓迎しております。</p>
							<p>学部学科や資格の有無等は選考を左右しませんが、出来れば現時点で持っていて欲しい、またはこれから伸ばせる資質や、当社に合った志向がありますのでぜひ参考にして下さい。</p>
						</div>
					</div>
				</div>
				<div className={CONST.KEY_VISUAL_RIGHT_HALF_IMG_AREA_CLASS}>
					<StaticImage quality="100" layout="fullWidth" alt="" placeholder={STATIC_IMAGE_PLACEHOLDER} className={CONST.PC_SHOW_CLASS + " w-full"} src={kv} />
					<div className={CONST.KEY_VISUAL_RIGHT_HALF_MESSAGE_AREA_CLASS + " xl:text-base"}>
						<div className="z-10">
							<h2 className={CONST.H1_CLASS}><span className="inline-block">当社に</span><span className="inline-block">マッチする志向</span></h2>
							<p className="xl:-mt-10">仕事も遊びもメリハリがあり、充実した生活をおくる魅力ある社会人になりたい人</p>
							<p>チームで物を作り出す仕事をしていきたい人</p>
							<p>手に職をつけて技術者になりたい人</p>
						</div>
						<StaticImage quality="100" layout="fullWidth" alt="" placeholder={STATIC_IMAGE_PLACEHOLDER} className="w-full absolute top-0 right-0 z-0" src={bg} />
					</div>
				</div>
			</section>

			<section className={CONST.SECTION_P_AREA_CLASS + " xl:-mt-16 2xl:-mt-24 3xl:-mt-16"}>
				<h2 className={CONST.H1_CLASS + " font-normal z-20 3xl:mb-7"}><span className="inline-block">当社が</span><span className="inline-block">求める資質</span></h2>
				<NEED />
			</section>

			<section className={CONST.SECTION_P_AREA_CLASS + " 3xl:-mt-32"}>
				<div className="w-full border-white border-2 p-5 xs:p-7 sm:p-10">
					<div className={CONST.SECTION_AREA_CLASS}>
						<div className="w-full lg:w-6/12">
							<div className="pb-5">
								<p className="font-bold">【月給】</p>
								<p>四大卒／院了／四年制専門：  230,000円</p>
								<p>三年制専門　　　　　　　：  220,000円</p>
								<p>二年制専門／短大卒　　　： 210,000円</p>
							</div>
							<div className="pb-5">
								<p className="font-bold">【諸手当】</p>
								<p>通勤手当、時間外勤務手当</p>
							</div>
							<div className="pb-5">
								<p className="font-bold">【給与改定】</p>
								<p>年2回（6月、12月）</p>
							</div>
							<div className="pb-5">
								<p className="font-bold">【賞与】</p>
								<p>年2回（6月、12月）</p>
							</div>
							<div className="pb-5 lg:pb-0">
								<p className="font-bold">【休日・休暇】</p>
								<p>
									<span className="inline-block">完全週休2日制（土・日）、</span>
									<span className="inline-block">祝日、夏季休暇（3日）、</span>
									<span className="inline-block">年末年始休暇</span>
								</p>
								<p>
									<span className="inline-block">年間休日125日＋有給休暇、</span>
									<span className="inline-block">慶弔休暇</span>
								</p>
							</div>
						</div>
						<div className="w-full lg:w-6/12">
							<div className="pb-5">
								<p className="font-bold">【福利厚生・制度】</p>
								<p>各種社会保険（健康保険※、厚生年金、雇用保険、労働者災害補償保険(労災)）</p>
								<p style={minText}>※東京都情報サービス産業健康保険組合(TJK)加入</p>
								<p>企業型確定拠出年金制度</p>
								<p>産休・育児休暇（取得実績有り）</p>
								<p>会員制リゾートホテルの利用（ＸＩＶ軽井沢サンクチュアリ・ヴィラ/グランドＸＩＶ初島クラブ/ＸＩＶ箱根離宮）</p>
								<p>社員旅行</p>
								<p>各種クラブ活動</p>
								<p>自社内新入社員研修（約3カ月）</p>
								<p>メンター制度</p>
								<p>テレワーク制度</p>
							</div>
							<div className="">
								<p className="font-bold">【勤務地】</p>
								<p>本社、当社事業所の他に、東京都内または都周辺の当社請負先</p>
								<p style={minText}>※転勤はありません</p>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className={CONST.SECTION_P_AREA_CLASS + CONST.SECTION_AREA_REVERSE_CLASS + " 3xl:-mt-24"}>
				<div className="w-full">
					<h2 className={CONST.H1_CLASS + CONST.PC_SHOW_CLASS + " font-normal 3xl:mb-7"}>選考プロセス</h2>
					<p>就職や採用のミスマッチを防ぎます。</p>
					<p>当社の募集職種はシステムエンジニアです。ですから、「入社したけど研修についていくことができない」という事態をお互いのために発生させてはなりません。</p>
					<p>この様なミスマッチをふせぐため、最終選考として「体験研修」を行っています。入社した際の研修内容と同一の研修を受けていただき（9：00～18：00）、適性試験だけでは分からない皆さんの様々な資質を見させていただきます。また、応募していただいた皆さんにとっては、教育体制や会社をじっくり見ていただき、本当に自分に合う仕事・会社であるかどうかを判断していただける機会になると思います。</p>
					<p className={CONST.LINE_SPACING_PB_CLASS}>「入社試験だから」と気負う必要はありません。ありのままの会社の姿をお見せしたいですし、ありのままの皆さんをお見せいただきたいと思います。</p>
				</div>
				<div className="w-full">
					<h2 className={CONST.H1_CLASS + CONST.PC_HIDDEN_CLASS + " font-normal"}>選考プロセス</h2>
					<div className="grid grid-cols-1 xl:grid-cols-6 gap-14 text-center font-semibold mb-5 xl:mb-0">
						<div className={flowBlockClass}>
							<p>STEP<span className={CONST.H3_CLASS + " font-normal w-full"}>1</span></p>
							{/* <p className={"bg-white text-black px-5 " + flowDiscriptionClass}>*/}
							<p className={flowDiscriptionClass}>
								<a className="bg-indigo text-white py-2 justify-center flex items-center border border-white rounded"
									href="https://job.mynavi.jp/25/pc/search/corp76197/outline.html" target="_blank" rel="noreferrer"><ExpandMoreIcon className="transform -rotate-90" /> エントリー</a>
							</p>
							<p>(マイナビ)</p>
							<span className="triangle-right-black invisible xl:visible"></span>
							<span className="triangle-bottom-black xl:hidden"></span>
						</div>
						<div className={flowWhiteClass}>
							<p>STEP<span className={CONST.H3_CLASS + " font-normal w-full"}>2</span></p>
							<p className={flowDiscriptionClass}>
								<span className="inline-block">会社説明会+</span>
								<span className="inline-block">適性試験</span>
							</p>
							<p>&nbsp;</p>
							<span className="triangle-right-white invisible xl:visible"></span>
							<span className="triangle-bottom-white xl:hidden"></span>
						</div>
						<div className={flowWhiteClass}>
							<p>STEP<span className={CONST.H3_CLASS + " font-normal w-full"}>3</span></p>
							<p className={flowDiscriptionClass}>面接1~2回</p>
							<p>&nbsp;</p>
							<span className="triangle-right-white invisible xl:visible"></span>
							<span className="triangle-bottom-white xl:hidden"></span>
						</div>
						<div className={flowWhiteClass}>
							<p>STEP<span className={CONST.H3_CLASS + " font-normal w-full"}>4</span></p>
							<p className={flowDiscriptionClass}>
								<span className="inline-block">体験研修</span>
								<span className="inline-block">（最終選考）</span>
							</p>
							<p>&nbsp;</p>
							<span className="triangle-right-white invisible xl:visible"></span>
							<span className="triangle-bottom-white xl:hidden"></span>
						</div>
						<div className={flowWhiteClass}>
							<p>STEP<span className={CONST.H3_CLASS + " font-normal w-full"}>5</span></p>
							<p className={flowDiscriptionClass}>内定</p>
							<p>&nbsp;</p>
						</div>
						<div className={flowBlockClass}>
							<p>STEP<span className={CONST.H3_CLASS + " font-normal w-full"}>6</span></p>
							<p className={flowDiscriptionClass}>入社</p>
							<p>&nbsp;</p>
						</div>
					</div>
				</div>
			</section>

			<section className={CONST.SECTION_AREA_CLASS}>
				<div className={CONST.CONTENTS_LEFT_IMG_AREA_CLASS + " xl:mt-24 3xl:mt-32"}>
					<h2 className={CONST.PC_HIDDEN_CLASS + CONST.H1_CLASS + " font-normal"}>よくある質問</h2>
					<StaticImage quality="100" layout="fullWidth" alt="" placeholder={STATIC_IMAGE_PLACEHOLDER} className="w-full -mb-10 xl:mb-0" src={img1} />
				</div>
				<div className={CONST.CONTENTS_RIGHT_MESSAGE_AREA_CLASS}>
					<div className="xl:-mt-16">
						<h2 className={CONST.PC_SHOW_CLASS + CONST.H1_CLASS + " font-normal 3xl:mb-7"}>よくある質問</h2>
						<QA />
					</div>
				</div>
				<StaticImage quality="100" layout="fullWidth" alt="" placeholder={STATIC_IMAGE_PLACEHOLDER} className="w-full absolute bottom-0 left-0 z-0" src={bg_b} />
			</section>
		</Layout>
	);
}

export default IndexPage;
